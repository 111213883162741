import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Masthead from '../components/masthead';
import Post from '../components/post-content';

export default ({ pageContext }) => {
	// Pull featured posts from settings.json
	let featuredFromSettings = [
		pageContext.featuredPosts.featuredPost1,
		pageContext.featuredPosts.featuredPost2,
		pageContext.featuredPosts.featuredPost3,
	];

	let featured = [];
	let i, j;

	for (i in featuredFromSettings) {
		// Check if featured posts from settings.json exist
		let exists = false;
		for (j in pageContext.allPosts) {
			if (pageContext.allPosts[j].node.shortTitle === featuredFromSettings[i])
				exists = true;
		}
		// If post exists, add to featured array
		if (exists) featured.push(featuredFromSettings[i]);
	}

	// Make copy of posts array
	let allPosts = pageContext.allPosts;

	// While featured array has less than 3 post titles in it, add latest posts in order
	while (featured.length < 3) {
		featured.push(allPosts[0].node.shortTitle);
		allPosts = allPosts.slice(1);
	}

	return (
		// Remove commas from title and change spaces to dashes
		<Layout
			page={'Resources/'.concat(
				pageContext.post.shortTitle.replace(/,/g, '').replace(/\s/g, '-')
			)}
		>
			<SEO
				title={pageContext.post.shortTitle}
				description={pageContext.description}
			/>
			<Masthead
				title="News & Resources"
				description="Stay Up To Date"
				image="/img/news_masthead.jpg"
				page="post"
			/>
			<Post
				post={pageContext.post}
				links={pageContext.links}
				latest={pageContext.allPosts.slice(0, 3)}
				featuredPosts={featured}
			/>
		</Layout>
	);
};
