import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import moment from 'moment';
import slugTransform from 'slug';

import mdToHtml from '../util/mdToHtml';

const StyledPost = styled.div`
	display: flex;
	flex-wrap: wrap;

	h1 {
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 2rem;
	}
	h2 {
		font-weight: 700;
	}
	h3 {
		color: ${(p) => p.theme.color.blue};
		text-transform: uppercase;
		font-size: 1rem;
		font-family: ${(p) => p.theme.fonts.title};
		margin: 0 !important;
	}
	h4 {
		font-weight: 700;
		font-size: 1.1rem;
		font-family: ${(p) => p.theme.fonts.title};
	}

	li {
		margin-bottom: 1rem;
		margin-left: 1rem;
	}
	ul li {
		position: relative;
		&::before {
			content: '';
			background-color: ${(p) => p.theme.color.orange};
			height: 7px;
			width: 7px;
			border-radius: 7px;
			position: absolute;
			left: -1rem;
			top: 8px;
		}
	}
	ol {
		margin: 0;
		margin-left: 0.5rem;
		padding: 0;
		counter-reset: item;
		li {
			margin-left: 0;
			padding-left: 0.5rem;
			text-indent: -0.5rem;
			list-style-type: none;
			counter-increment: item;
			&:before {
				display: inline-block;
				width: 0.5rem;
				padding-right: 0.25rem;
				font-weight: bold;
				text-align: right;
				position: relative;
				left: -5px;
				content: counter(item) '.';
			}
		}
	}

	.content-container {
		width: 70%;
		padding: 4rem 2rem 2rem 8rem;
		h1,
		h2 {
			margin: 1rem 0 0;
			color: ${(p) => p.theme.color.dark};
		}
		hr {
			margin: 1.5rem 0 0.8rem;
		}
		p {
			margin-bottom: 0.5rem;
		}
		a {
			color: ${(p) => p.theme.color.dark};
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.sidebar {
		width: 30%;
		padding: 3rem ${(p) => p.theme.gutter} 2rem 2rem;
		background-color: ${(p) => p.theme.color.blue};
		h2 {
			position: relative;
			padding-top: 2rem;
			&.first {
				padding: 0;
			}
			&::before {
				content: '';
				position: absolute;
				top: 1rem;
				left: 0;
				background-color: #044c71;
				width: 100px;
				height: 2px;
			}
			&.first::before {
				display: none;
			}
		}
		a {
			display: inline-block;
			color: #fff;
			margin-bottom: 0.3rem;
			line-height: 1.25rem;
			padding: 0.25rem 0;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.bottombar {
		width: 100%;
		padding: 2rem 30% 2rem 8rem;
		background-color: ${(p) => p.theme.color.orange};
		display: flex;
		justify-content: space-between;
		align-items: center;
		a {
			font-weight: 700;
			font-family: ${(p) => p.theme.fonts.title};
			color: #fff;
			position: relative;
			&::after {
				content: '';
				background-color: #fff;
				opacity: 0;
				transition: 0.5s;
				width: 100%;
				height: 2px;
				position: absolute;
				left: 0;
				bottom: 0;
			}
			&:hover::after {
				opacity: 1;
			}
		}
		h3 {
			color: #fff;
			span {
				font-weight: 700;
			}
		}
	}

	@media all and (min-width: 1600px) {
		.content-container {
			padding: 4rem 6rem 2rem 8rem;
		}
		h3 {
			font-size: 1.5rem;
			line-height: 2rem;
		}
		h4 {
			font-size: 1.3rem;
			line-height: 1.6rem;
		}
		.sidebar a {
			line-height: 1.8rem;
		}
	}
	@media all and (max-width: 900px) {
		.content-container {
			padding: 4rem 2rem 2rem 4rem;
		}
		.sidebar {
			padding: 3rem 2rem 2rem 2rem;
		}
		.bottombar {
			padding: 2rem 30% 2rem 4rem;
		}
	}
	@media all and (max-width: 768px) {
		.content-container {
			width: 100%;
			padding: 3rem 4rem;
		}
		.sidebar {
			width: 100%;
			padding: 2rem 4rem;
			.column-container {
				display: flex;
				justify-content: space-between;
				h2::before {
					display: none;
				}
				.col h2 {
					padding-top: 0;
				}
			}
		}
		.bottombar {
			padding: 2rem 4rem;
		}
	}
	@media all and (max-width: 668px) {
		.sidebar {
			display: flex;
			justify-content: center;
			.column-container {
				flex-direction: column;
				align-items: flex-start;
				.col {
					h2 {
						padding-top: 2rem;
						&.first {
							padding: 0;
						}
						&::before {
							display: block;
						}
						&.first::before {
							display: none;
						}
					}
				}
			}
		}
	}
	@media all and (max-width: 500px) {
		.content-container {
			width: 100%;
			padding: 2rem ${(p) => p.theme.gutterMobile} 1rem;
		}
		.sidebar {
			width: 100%;
			padding: 1rem ${(p) => p.theme.gutterMobile} 2rem;
		}
		.bottombar {
			padding: 2rem ${(p) => p.theme.gutterMobile};
			display: flex;
			justify-content: center;
			flex-direction: column;
			h3 {
				padding-top: 1rem;
			}
		}
	}
`;

export default ({ post, links, latest, featuredPosts }) => {
	return (
		<StyledPost>
			<div className="content-container">
				<h1>{post.title}</h1>
				<h3>{moment(post.date).format('MMMM DD YYYY')}</h3>
				<hr align="left" />
				<div
					dangerouslySetInnerHTML={{
						__html: mdToHtml(post.content),
					}}
				/>
			</div>
			<div className="sidebar">
				<div className="column-container">
					<div className="col">
						<h2 className="first">{links.titles[0]}</h2>
						<div className="link">
							<Link
								to={`/resources/${slugTransform(featuredPosts[0], {
									lower: true,
								})}/`}
							>
								{featuredPosts[0]}
							</Link>
						</div>
						<div className="link">
							<Link
								to={`/resources/${slugTransform(featuredPosts[1], {
									lower: true,
								})}/`}
							>
								{featuredPosts[1]}
							</Link>
						</div>
						<div className="link">
							<Link
								to={`/resources/${slugTransform(featuredPosts[2], {
									lower: true,
								})}/`}
							>
								{featuredPosts[2]}
							</Link>
						</div>
					</div>
					<div className="col">
						<h2>{links.titles[2]}</h2>
						{latest.map((x, i) => (
							<div className="link" key={i}>
								<Link to={x.node.fields.slug}>{x.node.shortTitle}</Link>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="bottombar">
				<Link to="/resources/">BACK TO RESOURCES</Link>
				<h3>
					<span>AUTHOR:</span> {post.author}
				</h3>
			</div>
		</StyledPost>
	);
};
